import { keyManagerDataProvider } from "app/network/dataProvider";
import { OperationType } from "app/network/models/constants";
import {
  EncryptionKey,
  EncryptionKeyRequestParams,
  SetEncryptionKeyStateResponse,
  ErrorResponse
} from "../models";
import { keyStatusMap } from "../utils/constants";

export const fetchEncryptionKey = async (
  satelliteID: number,
  params: { encryption_key_id: number }
): Promise<EncryptionKey | ErrorResponse | null> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.GET_LIST,
      `${satelliteID}/key/${params.encryption_key_id}`
    );
    return response.data;
  } catch (error) {
    return error as ErrorResponse;
  }
};

export const fetchAllEncryptionKey = async (
  satelliteID: number
): Promise<EncryptionKey[]> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.GET_LIST,
      `${satelliteID}/key`
    );
    return (Array.isArray(response.data) && response.data) || [];
  } catch (error) {
    return [];
  }
};

export const setEncryptionKeyState = async (
  satelliteID: number,
  params: EncryptionKeyRequestParams
): Promise<SetEncryptionKeyStateResponse> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.CREATE,
      `${satelliteID}/key/${params.encryption_key_id}/${
        keyStatusMap[params.state].endpoint
      }`
    );
    return response;
  } catch (error) {
    return error as ErrorResponse;
  }
};
