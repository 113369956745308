import { connect } from "react-redux";
import { fetchAllEncryptionKeyAction } from "app/keyManager/actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { KeyManager } from "app/keyManager/components/KeyManager";
const mapStateToProps = (state: any) => ({
  encryptionKeys: state.keyManager.encryptionKeys,
  selectedSatellite: state.constellations.dashboard.find(
    (satellite: any) => satellite.visible
  )
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchAllEncryptionKey: (satelliteID: number) =>
      dispatch(fetchAllEncryptionKeyAction(satelliteID))
  };
};

export const KeyManagerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyManager);
