import React, { useEffect, useReducer } from "react";
import { Heading } from "primitives";
import { KeyManagerProps, DataTypes } from "../models";
import styled from "styled-components";
import { initialState, reducer, KeyManagerContext } from "../context";
import { TabInput } from "app/shared";
import { TableVisualization } from "./TableVisualization";

const Container = styled("div")`
  background-color: #2e4162;
  padding: 20px 10px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;
const KeyManagerRoot = styled("div")`
  display: flex;
  flex-flow: column;
  height: 100%;
`;
const HeightFiller = styled("div")`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const tabLabels = Object.values(DataTypes);

export const KeyManager = ({
  fetchAllEncryptionKey,
  encryptionKeys,
  selectedSatellite
}: KeyManagerProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { contextMenu } = state;

  useEffect(() => {
    if (selectedSatellite?.id) {
      fetchAllEncryptionKey(selectedSatellite.id);
    }
  }, [selectedSatellite]);

  return (
    <KeyManagerContext.Provider value={{ state, dispatch }}>
      <KeyManagerRoot data-testid={"key-manager-root"}>
        <Heading display={1} marginBottom={4}>
          Key Manager
        </Heading>
        <Container>
          <HeightFiller>
            <TabInput
              margin="0 16px"
              record={null}
              tabs={tabLabels}
              containers={(value: number) => (
                <TableVisualization dataType={value} />
              )}
            />
          </HeightFiller>
        </Container>
        {contextMenu}
      </KeyManagerRoot>
    </KeyManagerContext.Provider>
  );
};
