import {
  Oa,
  OperationActivityFilterState,
  CalendarTimelineItem,
  Passage,
  ITimeline,
  TimelineEntry,
  TimelineTypes,
  TimelineEntryType,
  ISODateString,
  CalendarTimeline,
  FetchTimelineResponse,
  TimelineWindow,
  OaHandleState,
  CreateOaParams,
  PatchOaParams,
  HandleType,
  OperationDialogActionName,
  SchemaErrors,
  FOPType,
  Overlaps,
  TimelineItemResizeSide
} from "../models";
import { getDateAtUTCOffset } from "components/TimelineRenderer/utils/helpers";
import { fetchProcedure } from "app/procedure/services";
import { fetchScript, getScriptResourceUrl } from "app/scripting/services";
import { passageTimelineGroup, calendartimelineGroups } from "./constants";
import palette from "styles/palette";
import { getSatelliteName } from "app/shared/utils";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { store } from "app/store";
import { isEqual } from "lodash";
import yaml from "js-yaml";
import moment from "moment";

/**
 * @description TODO: TEST
 * @param filters
 * @param operations
 * @returns
 */
export const filterOperations = (
  filters: OperationActivityFilterState,
  operations: Oa[]
): Oa[] => {
  let filteredOperations: Oa[] = operations;
  for (const filterKey in filters) {
    if (
      Object.hasOwnProperty.call(filters, filterKey) &&
      typeof filters[filterKey as keyof OperationActivityFilterState] ===
        "string"
    ) {
      const _operations = filteredOperations.filter((op: Oa) => {
        const operationVal =
          (op && (op[filterKey as keyof Oa] as string).toLowerCase()) || "";
        const appliedFilter = (
          filters[filterKey as keyof OperationActivityFilterState] as string
        ).toLowerCase();
        return operationVal.includes(appliedFilter);
      });

      filteredOperations = [..._operations];
    }
  }

  return filteredOperations;
};

/**
 * @description remap Passage[] to CalendarTimelineItem[]
 * @param passages
 * @returns
 */
export const passageToTimeline = (
  passages: Passage[]
): CalendarTimelineItem[] => {
  const { satelliteInstances } = store.getState().constellations.selected || {};

  return passages.map((passage) => ({
    id: passage.passageID,
    group: passageTimelineGroup,
    start_time: getDateAtUTCOffset(passage.aos),
    end_time: getDateAtUTCOffset(passage.los),
    canMove: false,
    canResize: false,
    canChangeGroup: false,
    title: `Satellite: ${getSatelliteName(
      passage.satelliteID,
      satelliteInstances
    )} - over - GS: ${passage.groundStationName}`,
    itemProps: {
      style: {
        background: palette.palette.purple[0]
      }
    }
  }));
};

/**
 * Given a list of CalendarTimelineItem it return the event much closer to the present UTC time
 * based on the start_time attribute.
 * @param eventList
 */
export const getNearestEvent = (
  eventList: CalendarTimelineItem[]
): CalendarTimelineItem | null => {
  const currentTime = getDateAtUTCOffset(new Date().toISOString());

  // Filter out events that are already in progress or have ended
  const futureEvents = eventList.filter(
    (event) => event.start_time > currentTime
  );

  if (futureEvents.length === 0) {
    return null; // No future events found
  }

  // Sort the future events by start_time in ascending order
  futureEvents.sort((a, b) => a.start_time - b.start_time);

  // Return the first event from the sorted array
  return futureEvents[0];
};

/**
 * @description This function takes as input a number indicating a range of hours
 * and return two dates that position the current time at the middle of the time range taken as input.
 * @param hours {number}
 * @returns { timeStart, timeEnd }
 */
export const getTimelineTimeWindow = (hours = 8): TimelineWindow => {
  const oneHour = 3600 * 1000;
  const now = new Date().valueOf();
  const timeOffset = (hours / 2) * oneHour;

  const startTime = new Date(now - timeOffset);
  const endTime = new Date(now + timeOffset);

  return { startTime, endTime };
};

/**
 * @description given an array of TimelineEntry it remap to CalendarTimelineItem[]
 * @param entries
 * @param timelineType
 * @returns
 */
export const timelineEntryToCalendar = (
  entries: TimelineEntry[],
  timelineType: TimelineTypes
): CalendarTimelineItem[] => {
  return entries.map((entry) => ({
    id: `${entry.oaUUID}-${entry.timelineEntryType}`,
    canChangeGroup: false,
    group: calendartimelineGroups[timelineType].id,
    canMove:
      timelineType !== TimelineTypes.MASTER &&
      entry.timelineEntryType === TimelineEntryType.EXECUTE,
    canResize:
      timelineType !== TimelineTypes.MASTER &&
      entry.timelineEntryType === TimelineEntryType.EXECUTE &&
      TimelineItemResizeSide.both,
    title: `BookedSatellite: ${entry.bookedSatellite}`,
    start_time: getDateAtUTCOffset(entry.executionStart),
    end_time: getDateAtUTCOffset(entry.executionEnd),
    className: ""
  }));
};

/**
 * @description Map FetchAllTimelineResponse to CalendarTimeline
 * @param param0
 * @returns
 */
export const timelineToCalendarTimeline = (
  timeline: FetchTimelineResponse
): CalendarTimeline[] => {
  return [timeline].map(({ uuid, name, timelineType, entries }) => ({
    uuid,
    name,
    timelineType,
    groups: [calendartimelineGroups[timelineType]],
    items: timelineEntryToCalendar(entries, timelineType)
  }));
};

/**
 * @description Loop over the local TimelineEntrie[]
 * getting the related CalendarTimelineItem at each iteration and update the
 * TimelineEntry `executionStart` end `executionEnd`.
 * Used to prepare the paylod for the update timeline request.
 * @param calendarTimelineItem
 * @param timeline
 * @returns
 */
export const reverseCalendarTimeline = (
  calendarTimelineItem: CalendarTimelineItem[],
  timeline: ITimeline
): TimelineEntry[] => {
  const timelineEntries: TimelineEntry[] = [];
  calendarTimelineItem.forEach((ctItem) => {
    const timelineEntry = timeline.entries.find(
      (entry) => `${entry.oaUUID}-${entry.timelineEntryType}` === ctItem.id
    );

    if (timelineEntry)
      timelineEntries.push({
        ...timelineEntry,
        executionStart: toISOStringWithLocalTimezone(
          new Date(ctItem?.start_time)
        ) as ISODateString,
        executionEnd: toISOStringWithLocalTimezone(
          new Date(ctItem?.end_time)
        ) as ISODateString
      });
  });

  return timelineEntries;
};

/**
 * @description This is a workaround since the react-calendar-timeline does not support UTC and
 * its item are controlled with local dates.
 * @param date
 * @returns return a string rappresenting a locale date with UTC format
 */
export const toISOStringWithLocalTimezone = (date: Date): string => {
  const pad = (num: number) => String(num).padStart(2, "0");

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
};

/**
 * @description It remap the OaHandleState (formData) to create the body od request for edit/create
 * basesd on the value of handleType (create|edit)
 * @param handleFormData
 * @param handleType
 * @returns CreateOaParams | PatchOaParams
 */
export const handleRequestBody = (
  handleFormData: OaHandleState,
  handleType: HandleType
): CreateOaParams | PatchOaParams | null => {
  try {
    const parameters =
      handleFormData.fop.parameters &&
      yamlToJson(handleFormData.fop.parameters);
    const logFileName = handleFormData.fop.logFileName;

    const mopOptions =
      handleFormData.fopType === FOPType.MOP
        ? {
            satelliteID: handleFormData.satelliteID,
            missionEntities: handleFormData.missionEntities.map(
              ({ entity }) => entity
            ),
            procedureExecutionStartTs:
              handleFormData.procedureExecutionSetting.startTime instanceof Date
                ? handleFormData.procedureExecutionSetting.startTime.toISOString()
                : handleFormData.procedureExecutionSetting.startTime,
            procedureExecutionDuration:
              handleFormData.procedureExecutionSetting.durationTime
          }
        : {};

    const oa = {
      name: handleFormData.name,
      description: handleFormData.description,
      oaType: handleFormData.oaType,
      priority: handleFormData.priority,
      taskStartTs:
        handleFormData.taskSetting.startTime instanceof Date
          ? handleFormData.taskSetting.startTime.toISOString()
          : handleFormData.taskSetting.startTime,
      taskDuration: handleFormData.taskSetting.durationTime,
      ...mopOptions
    };

    const fop = {
      type: handleFormData.fopType,
      templateProcedureID: handleFormData.fop.templateProcedureID,
      ...((parameters && { parameters }) || {}),
      logFileName
    };

    const editProps = {
      fopParameters: parameters,
      fopLogFileName: logFileName
    };

    const createParams = { oa, fop } as CreateOaParams;
    const patchParams = { ...oa, ...editProps } as PatchOaParams;

    /**
     * Returning create/edit request parameters
     */
    return (
      (handleType === OperationDialogActionName.create && createParams) ||
      patchParams
    );
  } catch (error) {
    store.dispatch(
      setFeedback(
        "Error",
        FeedbackStatus.ERROR,
        "Check all the fields of the form"
      )
    );
    return null;
  }
};

/**
 * @description Used in the EDIT form. The received OA is remapped to OaHandleState
 * to be passed to the form as `formData`
 * @param operation
 * @returns OaHandleState
 */
export const operationToFormState = (
  operation: Oa,
  handleType: HandleType
): OaHandleState | null => {
  try {
    return {
      name:
        handleType === OperationDialogActionName.create
          ? undefined
          : operation.name,
      description: operation.description,
      oaType: operation.oaType,
      priority: operation.priority,
      fopType: operation.fop.type,
      satelliteID: operation.satelliteID,
      missionEntities: operation.missionEntities.map((entity) => ({ entity })),
      procedureExecutionSetting: {
        startTime:
          handleType === OperationDialogActionName.create
            ? undefined
            : operation.procedureExecutionStartTs &&
              new Date(
                operation.procedureExecutionStartTs?.endsWith("Z")
                  ? operation.procedureExecutionStartTs
                  : `${operation.procedureExecutionStartTs}Z`
              ),
        durationTime:
          handleType === OperationDialogActionName.create
            ? undefined
            : operation.procedureExecutionDuration
      },
      taskSetting: {
        startTime:
          handleType === OperationDialogActionName.create
            ? undefined
            : operation.taskStartTs &&
              new Date(
                operation.taskStartTs?.endsWith("Z")
                  ? operation.taskStartTs
                  : `${operation.taskStartTs}Z`
              ),
        durationTime:
          handleType === OperationDialogActionName.create
            ? undefined
            : operation.taskDuration
      },
      fop: {
        templateProcedureID: operation.fop.templateProcedureID,
        ...((operation.fop.resources && {
          resources: operation.fop.resources.map((resource) => ({ resource }))
        }) ||
          {}),
        ...((operation.fop.parameters && {
          operation: jsonToYaml(operation.fop.parameters)
        }) ||
          {}),
        logFileName: operation.fop.logFileName
      }
    };
  } catch (error) {
    store.dispatch(
      setFeedback(
        "Error",
        FeedbackStatus.ERROR,
        "Operation Activity corrupted. Unable to open it."
      )
    );
    console.log(error);
    return null;
  }
};

/**
 * @description Make a validation for the create/edit OA form.
 * It checks that the multivalue fields have no duplicated values.
 * The required fields are validated automaticly in the schema.
 * @param formData
 * @param errors
 * @returns SchemaErrors
 */
export const handleFormValidator = (
  formData: OaHandleState,
  errors: SchemaErrors
) => {
  /**
   * procedureExecutionStartTs > taskStartTs
   */
  if (
    formData.procedureExecutionSetting?.startTime &&
    formData.taskSetting?.startTime
  ) {
    const procedureExecutionStartTs = new Date(
      formData.procedureExecutionSetting.startTime
    );
    const taskStartTs = new Date(formData.taskSetting.startTime);
    if (procedureExecutionStartTs <= taskStartTs) {
      errors.procedureExecutionSetting.startTime.addError(
        "Procedure execution start time must be higher than task start time"
      );
    }
  }

  if (
    formData.fopType === FOPType.MOP &&
    !formData.procedureExecutionSetting?.startTime
  ) {
    errors.procedureExecutionSetting.startTime.addError(
      "Procedure execution start time must be a valid date string"
    );
  }

  if (!formData.taskSetting?.startTime) {
    errors.taskSetting.startTime.addError(
      "Start time must be a valid date string"
    );
  }

  /**
   * Parameters
   */
  if (formData.fop.parameters) {
    const json = yamlToJson(formData.fop.parameters);
    if (!json || typeof json !== "object") {
      errors.fop.parameters.addError("Invalid YAML");
    }
  }

  /**
   * Mission entities
   */
  if (formData.missionEntities && formData.missionEntities.length > 0) {
    formData.missionEntities.forEach((e, i, entities) => {
      const isDuplicated =
        entities.filter((entity) => isEqual(e, entity)).length > 1;
      if (isDuplicated) {
        errors.missionEntities[i].addError("Duplicated entity");
      }
    });
  }

  return errors;
};

const jsonToYaml = (json: object): string => {
  try {
    // Convert the JS object to a YAML
    return yaml.dump(json);
  } catch (e) {
    console.error("Error while converting from JSON to YAML:", e);
    return "";
  }
};

const yamlToJson = (yamlString: string): object | null => {
  try {
    // Convert a YAML into a js object
    return yaml.load(yamlString) as object;
  } catch (e) {
    console.error("Error while converting from YAML to JSON:", e);
    return null;
  }
};

const readResourceFile = async (downloadUrl: string): Promise<string> => {
  try {
    const response = await fetch(downloadUrl);

    return response.text();
  } catch (error: unknown) {
    // eslint-disable-next-line no-restricted-globals
    if (location.origin === "https://localhost:3000") {
      console.error(
        "Fetching AWS from https://localhost:3000 is disabled for security issues"
      );
    } else {
      console.error("An unknown error occurred");
    }
    return "An error occurred while getting the template";
  }
};

const getYamlTemplate = async (procedureId: number): Promise<string> => {
  try {
    const procedure = await fetchProcedure(procedureId);
    const firstStep = procedure?.steps.find((step) => step.order === 1);
    if (!firstStep) {
      store.dispatch(
        setFeedback(
          "Error",
          FeedbackStatus.ERROR,
          "Could not find a step with order 1 in the procedure"
        )
      );
      return "";
    }

    const script = await fetchScript(String(firstStep.scriptId));
    if (script.resources.length === 0) {
      store.dispatch(
        setFeedback(
          "Error",
          FeedbackStatus.ERROR,
          "Could not find any resource for this step"
        )
      );
      return "";
    }
    const resource = script.resources.find((r) =>
      r.s3ObjectName.includes(procedure.name)
    );

    if (!resource) {
      store.dispatch(
        setFeedback(
          "Error",
          FeedbackStatus.ERROR,
          `Could not find ${procedure.name}.yaml`
        )
      );
      return "";
    }

    const resourceUrl = await getScriptResourceUrl(resource.id);
    if (!resourceUrl) {
      store.dispatch(
        setFeedback("Error", FeedbackStatus.ERROR, `Resource url not valid`)
      );
      return "";
    }

    const resourceContent = await readResourceFile(resourceUrl.url);

    return resourceContent || `Template file is empty`;
  } catch (e) {
    // TODO: notify error while getting the yaml template
    return "";
  }
};

export const handleYamlTemplate = async (
  formData: OaHandleState
): Promise<OaHandleState> => {
  try {
    const yamlTemplate = await getYamlTemplate(
      formData.fop.templateProcedureID
    );

    if (typeof yamlTemplate === "string") {
      return {
        ...formData,
        fop: { ...formData.fop, parameters: yamlTemplate }
      };
    }

    return formData;
  } catch (error) {
    console.log(error);
    return formData;
  }
};

/**
 * Given a list of CalendarTimelineItem it checks if all the existing items
 * of type TRACK overlaps with its related EXECUTE item.
 * If yes it return both the overlapping items.
 */
export const executeOverlapsToTrack = (
  timelineItems: CalendarTimelineItem[]
): Overlaps => {
  const trackItems = timelineItems.filter((itm) =>
    String(itm.id).includes(TimelineEntryType.TRACK)
  );
  const executeItems = timelineItems.filter((itm) =>
    String(itm.id).includes(TimelineEntryType.EXECUTE)
  );

  // Find the first overlap between track and execute items
  for (const track of trackItems) {
    const trackRoot = String(track.id).split("-");
    trackRoot.pop(); // Remove the last part of the track ID

    const relatedExecute = executeItems.find((execute) =>
      String(execute.id).includes(trackRoot.join("-"))
    );

    if (relatedExecute && relatedExecute.end_time > track.start_time) {
      // Return the first pair that satisfies the condition
      return { track, relatedExecute };
    }
  }

  // If no overlaps are found, return null
  return null;
};

/**
 * @description Return a function used in the DateTimePicker to determinate valid dates to be picked
 * @param days how many days before today dates can be valid
 * @returns Function
 */
export const isValidDate = (days = 1) => {
  const yesterday = moment().subtract(days, "day");
  return function (current: any) {
    return current.isAfter(yesterday);
  };
};

/**
 * @description given a timelineItemID it strip out the information of TimelineEntryType
 * and return the OA uuid.
 * @param timelineItemID
 * @returns
 */
export const stripTypeFromOauuid = (timelineItemID: string) => {
  return timelineItemID.substring(0, timelineItemID.lastIndexOf("-"));
};

/**
 * @description Given a TimelineEntry[] it return the TimelineEntry with the earlier
 * `executionStart`. The comparison is made among ISODateString since this format preserves
 * chronological order even in alphabetical order, string comparison works directly with < and >.
 * @param entries
 * @returns TimelineEntry
 */
export const getEarliestTimelineEntry = (
  entries: TimelineEntry[]
): TimelineEntry | null => {
  if (entries.length === 0) {
    return null;
  }

  return entries.reduce((earliest, current) =>
    current.executionStart < earliest.executionStart ? current : earliest
  );
};
