import React, { useEffect, useState } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import {
  DataTypes,
  EncryptionKey,
  Channels,
  InitializationVector
} from "../models";
import { useSelector } from "react-redux";

interface TableVisualizationProps {
  dataType: number;
}

const tabLabels = Object.values(DataTypes);

export const TableVisualization = ({ dataType }: TableVisualizationProps) => {
  const { encryptionKeys, initializationVectors } = useSelector<any, any>(
    (state: any) => state.keyManager
  );

  const [tableData, setTableData] = useState<
    EncryptionKey[] | InitializationVector[]
  >([]);

  useEffect(() => {
    setTableData(getTabledata(dataType));
  }, [dataType, encryptionKeys, initializationVectors]);

  const getTabledata = (
    dataType: number
  ): EncryptionKey[] | InitializationVector[] => {
    switch (tabLabels[dataType]) {
      case DataTypes.INITIALIZATION_VECTORS:
        return initializationVectors;
      case DataTypes.KEY_OBC:
        return encryptionKeys.filter(
          (k: EncryptionKey) => k.channel === Channels.OBC
        );
      case DataTypes.KEY_PMU:
        return encryptionKeys.filter(
          (k: EncryptionKey) => k.channel === Channels.PMU
        );

      default:
        return [];
    }
  };

  return (
    <Table marginTop={20} maxWidth={"100%"}>
      <TableHead>
        <TableRow bg="fill.0">
          <TableCell width="auto">Satellite Instance ID</TableCell>
          <TableCell width="auto">Satellite Key ID</TableCell>
          <TableCell width="auto">State</TableCell>
          <TableCell width="auto">Channel</TableCell>
          <TableCell width="auto">Activated at</TableCell>
          <TableCell width="auto">Expires at</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData.map((td, idx) => (
          <TableRow key={idx} data-testid={"key-tr"}>
            <TableCell>{td.satellite_instance_id}</TableCell>
            <TableCell>{td.satellite_key_id}</TableCell>
            <TableCell>{td.state}</TableCell>
            <TableCell>{td.channel}</TableCell>
            <TableCell>{td.activated_at || "n/a"}</TableCell>
            <TableCell>{td.expires_at || "n/a"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
