import {
  FetchAllEncryptionKeyAction,
  SetEncryptionKeyStateAction,
  FetchAllEncryptionKeyActionResult,
  KeyManagerActionType,
  SetEncryptionKeyStateResult,
  EncryptionKeyRequestParams,
  SuccessResponse,
  FetchEncryptionKeyActionResult,
  FetchEncryptionKeyAction,
  EncryptionKey
} from "../models";
import { Dispatch } from "react";
import {
  fetchAllEncryptionKey,
  fetchEncryptionKey,
  setEncryptionKeyState
} from "../services/encryptionKey";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { store } from "app/store";

export const fetchEncriptionKeyAction = (
  satelliteID: number,
  params: { encryption_key_id: number }
): FetchEncryptionKeyActionResult => {
  return async (dispatch: Dispatch<FetchEncryptionKeyAction>) => {
    try {
      const payload = await fetchEncryptionKey(satelliteID, {
        encryption_key_id: params.encryption_key_id
      });
      dispatch({
        type: KeyManagerActionType.FetchEncryptionKey,
        payload: payload as EncryptionKey
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve(null);
    }
  };
};

export const fetchAllEncryptionKeyAction = (
  satelliteID: number
): FetchAllEncryptionKeyActionResult => {
  return async (dispatch: Dispatch<FetchAllEncryptionKeyAction>) => {
    try {
      const payload = await fetchAllEncryptionKey(satelliteID);
      dispatch({
        type: KeyManagerActionType.FetchAllEncryptionKey,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve(null);
    }
  };
};

export const setEncryptionKeyStateAction = (
  satelliteID: number,
  params: EncryptionKeyRequestParams
): SetEncryptionKeyStateResult => {
  return async (dispatch: Dispatch<SetEncryptionKeyStateAction>) => {
    try {
      const response = await setEncryptionKeyState(satelliteID, params);

      if ((response as SuccessResponse).status !== 204) {
        store.dispatch(
          setFeedback(
            (response as SuccessResponse)?.data.type || "Error",
            FeedbackStatus.ERROR,
            (response as SuccessResponse)?.data.description ||
              (response as SuccessResponse)?.data.detail
          )
        );
        return Promise.resolve(null);
      }

      dispatch({
        type: KeyManagerActionType.SetEncryptionKeyState,
        payload: params
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(null);
    }
  };
};
