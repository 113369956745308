import { SelectedSatellite } from "app/satelliteDefinition/models";
import React, { Dispatch } from "react";
import { ThunkAction } from "redux-thunk";
import {
  StatusIndicatorTheme,
  ISODateString
} from "components/CountDown/models";

/**
 * Entities
 */
export interface EncryptionKey {
  key_id: number;
  satellite_instance_id: number;
  satellite_key_id: number;
  state: KeyStatus;
  channel: Channels | null;
  activated_at: string | null;
  expires_at: string | null;
}

export type InitializationVector = any;

export enum KeyStatus {
  PRE_OPERATIONAL = "PRE_OPERATIONAL",
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  DEACTIVATED = "DEACTIVATED",
  DESTROYED = "DESTROYED"
}

export type KeyStatusMap = {
  [key in keyof typeof KeyStatus]: {
    color: string;
    name: string;
    endpoint?: string;
    actionName?: string;
  };
};

export type ChannelMaps = {
  [key in keyof typeof Channels]: {
    label: string;
    bg: string;
  };
};

export type FunctionalCategoriesMaps = {
  [key in keyof typeof FunctionalCategories]: {
    label: string;
  };
};

export enum Channels {
  OBC = "OBC",
  PMU = "PMU"
}

export enum FunctionalCategories {
  TEK = "TEK",
  TAK = "TAK"
}

export enum DataTypes {
  KEY_OBC = "KEY OBC",
  KEY_PMU = "KEY PMU",
  INITIALIZATION_VECTORS = "INITIALIZATION VECTORS"
}

/**
 * Redux
 */
export interface KeyManagerStoreState {
  encryptionKeys: EncryptionKey[];
  initializationVectors: InitializationVector[];
}

export enum KeyManagerActionType {
  FetchEncryptionKey = "FETCH_ENCRYPTION_KEY",
  FetchAllEncryptionKey = "FETCH_ALL_ENCRYPTION_KEY",
  SetEncryptionKeyState = "SET_ENCRYPTION_KEY_STATE"
}

export interface FetchEncryptionKeyAction {
  type: KeyManagerActionType.FetchEncryptionKey;
  payload: EncryptionKey | null;
}

export interface FetchAllEncryptionKeyAction {
  type: KeyManagerActionType.FetchAllEncryptionKey;
  payload: EncryptionKey[] | null;
}

export interface SetEncryptionKeyStateAction {
  type: KeyManagerActionType.SetEncryptionKeyState;
  payload: EncryptionKeyRequestParams | null;
}

export interface EncryptionKeyRequestParams {
  encryption_key_id: number;
  state: KeyStatus;
}

export type KeyManagerActions =
  | FetchEncryptionKeyAction
  | FetchAllEncryptionKeyAction
  | SetEncryptionKeyStateAction;

export interface SetEncryptionKeyStateAction {
  type: KeyManagerActionType.SetEncryptionKeyState;
  payload: EncryptionKeyRequestParams | null;
}

export interface ErrorResponse {
  detail: [
    {
      loc: ["string", 0];
      msg: "string";
      type: "string";
    }
  ];
}

export interface SuccessResponse {
  data: any;
  status: number;
}
export interface MessageResponse {
  message: string;
}

export type FetchEncryptionKeyActionResult = ThunkAction<
  Promise<EncryptionKey | ErrorResponse | null>,
  {},
  {},
  FetchEncryptionKeyAction
>;

export type FetchAllEncryptionKeyActionResult = ThunkAction<
  Promise<EncryptionKey[] | null>,
  {},
  {},
  FetchAllEncryptionKeyAction
>;

export type SetEncryptionKeyStateResult = ThunkAction<
  SetEncryptionKeyStateResponse,
  {},
  {},
  SetEncryptionKeyStateAction
>;

export type SetEncryptionKeyStateResponse = Promise<
  MessageResponse | SuccessResponse | ErrorResponse | null
>;

export type ChannelsName = keyof typeof Channels;
export type FunctionalCategoriesName = keyof typeof FunctionalCategories;

// STATE
export interface ContextMenuState {
  coord: {
    x: number;
    y: number;
  };
  content: React.ReactNode;
}
export interface IContext {
  contextMenu: React.ReactNode | null;
  changeStateDialog: ChangeStateDialog | null;
}

export enum ContextActionsNames {
  contextMenu = "contextMenu",
  changeStateDialog = "changeStateDialog"
}

export type IContextActions = {
  type?: ContextActionsNames;
  payload: Partial<IContext> | IContext[keyof IContext];
};

/**
 * Components
 */
// PROPS

export interface ChangeStateDialog {
  key: EncryptionKey;
  isOpen: boolean;
  setOpen: Dispatch<IContextActions>;
  state: KeyStatus;
}

export interface EncryptionKeyListProps {
  encryptionKeys: EncryptionKey[];
}

export interface KeyManagerProps {
  encryptionKeys: EncryptionKey[];
  fetchAllEncryptionKey: (
    satelliteID: number
  ) => Promise<EncryptionKey[] | null>;
  selectedSatellite: SelectedSatellite;
}
